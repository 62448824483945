import { AppShell, Header, Footer } from '@mantine/core';
import MintKitFrame from './MintKitFrame';
import Tabs from './Tabs';
import ProjectInjector from './ProjectInjector';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

const colors = [
  '#C8B7A6',
  '#7D94B5',
  '#C29591',
  '#703F37',
  '#B6C199',
  '#ABD699',
  '#FFE26A',
  '#75C9B7',
  '#C7DDCC',
  '#16123F',
  '#A96762',
  '#2F5C8f',
  '#D85C27',
  '#B999BE',
  '#14365D',
  '#F4D8AE',
  '#DC7027',
  '#B6C48E',
  '#EA8A81',
  '#F4d8ae',
  '#dc7027',
  '#b6c48e',
  '#ea8a81',
  '#301008',
  '#4cbfa6',
  '#f6ebf4',
  '#482673',
  '#ed0b70',
  '#301008',
];

const App = () => {
  const [color, setColor] = useState(
    colors[Math.floor(Math.random() * colors.length)]
  );

  useEffect(() => {
    setInterval(() => {
      let newColor = colors[Math.floor(Math.random() * colors.length)];
      while (newColor === color) {
        newColor = colors[Math.floor(Math.random() * colors.length)];
      }

      setColor(newColor);
    }, 30000);
  }, []);

  return (
    <AppShell
      padding={0}
      className="transition-all overflow-auto"
      css={css`
        background-color: ${color};
      `}
      header={
        <Header
          style={{
            background: 'transparent',
            position: 'fixed',
            top: 0,
            border: 'none',
            margin: 35,
            display: 'flex',
            justifyContent: 'space-between',
          }}
          height={0}
          p="xs"
        >
          <span
            style={{
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: '700',
              letterSpacing: '.21em',
              textTransform: 'uppercase',
              lineHeight: '1em',
              color: 'white',
            }}
          >
            Mintkit
          </span>
          <Tabs />
        </Header>
      }
    >
      <ProjectInjector />
      <MintKitFrame />
    </AppShell>
  );
};

export default App;

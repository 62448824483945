import { useLocation, useStartTyping } from 'react-use';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

const MintKitFrame = () => {
  const location = useLocation();

  useEffect(() => {
    const { height, width } = queryString.parse(location?.search || '');

    const normalizeNumber = (number: string) =>
      Number.isNaN(Number.parseInt(number as string))
        ? (number as string)
        : (`${number}px` as string);
  }, []);

  return (
    <div className="flex justify-center items-start h-full w-full relative mb-11">
      <div className="absolute top-32" id="mintkit-frame" />
    </div>
  );
};

export default MintKitFrame;

import { Tabs } from '@mantine/core';
import { Menu, Button, Text } from '@mantine/core';

const TabsComponent = () => {
  return (
    <Tabs
      defaultValue="gallery"
      style={{
        width: 600,
        color: 'white',
        border: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Tabs.List style={{ border: 'none' }}>
        <Menu
          offset={20}
          shadow="md"
          width={200}
          trigger="hover"
          openDelay={100}
          closeDelay={400}
        >
          <Menu.Target>
            <span
              className="uppercase mr-8 cursor-pointer"
              style={{
                fontSize: 12,
                background: 'transparent',
                color: 'white',
              }}
            >
              Phases
            </span>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              onClick={() =>
                window.Pluto?.updateProjectID(
                  process.env.EARLY_ACCESS_PROJECT_ID
                )
              }
            >
              Early Access
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                window.Pluto?.updateProjectID(
                  process.env.PRIVATE_MINT_PROJECT_ID
                );
              }}
            >
              Private Mint
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                window.Pluto?.updateProjectID(
                  process.env.PUBLIC_MINT_PROJECT_ID
                )
              }
            >
              Public Mint
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Menu
          offset={20}
          shadow="md"
          width={200}
          trigger="hover"
          openDelay={100}
          closeDelay={400}
        >
          <Menu.Target>
            <span
              className="uppercase mr-4 cursor-pointer"
              style={{
                fontSize: 12,
                background: 'transparent',
                color: 'white',
              }}
            >
              Themes
            </span>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => window.Pluto?.updateBaseTheme('Chrome')}>
              Chrome
            </Menu.Item>
            <Menu.Item
              onClick={() => window.Pluto?.updateBaseTheme('FrogOnALog')}
            >
              Frog On A Log
            </Menu.Item>
            <Menu.Item onClick={() => window.Pluto?.updateBaseTheme('KoolAid')}>
              KoolAid
            </Menu.Item>
            <Menu.Item onClick={() => window.Pluto?.updateBaseTheme('Pluto')}>
              Pluto
            </Menu.Item>
            <Menu.Item
              onClick={() => window.Pluto?.updateBaseTheme('Sunshine')}
            >
              Sunshine
            </Menu.Item>
            <Menu.Item onClick={() => window.Pluto?.updateBaseTheme('Tea')}>
              Tea
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Tabs.Tab
          value="ABOUT"
          style={{ fontSize: 12, background: 'transparent', color: 'white' }}
        >
          ABOUT
        </Tabs.Tab>
        <Tabs.Tab
          value="CONTACT"
          style={{ fontSize: 12, background: 'transparent', color: 'white' }}
        >
          CONTACT
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default TabsComponent;

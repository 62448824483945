import { useLocation } from 'react-use';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

type ProjectInjectorProps = {};

const ProjectInjector = (props: ProjectInjectorProps) => {
  const [projectID, setProjectID] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    const { project_id } = queryString.parse(location?.search || '');

    if (project_id) {
      setProjectID(project_id as string);
    }
  }, []);

  useEffect(() => {
    if (!projectID) return;

    const scriptEle = document.createElement('script');
    scriptEle.type = 'text/javascript';
    scriptEle.src = `${process.env.SDK_URL}?k=${projectID}`;
    document.head.appendChild(scriptEle);
  }, [projectID]);

  return <div></div>;
};

export default ProjectInjector;
